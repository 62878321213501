<style>
.load-more {
  @apply text-lg font-medium;
  @apply cursor-pointer;
  color: var(--primary);
}

.load-more:hover {
  @apply underline;
}

.claim-icon {
  color: var(--primary);
  display: table !important;
  @apply cursor-pointer ml-auto;
}

.row-renewal {
  @apply bg-gray-100;
}

.row-followUp {
  @apply bg-red-100;
}
</style>

<script>
import { onMount } from "svelte"
import { navigate } from "svelte-routing"
import { getListByDefKey, patchLanguage } from "@local/config/datasource"
import TemplateString from "@shared/components/TemplateString.svelte"
import TextInput from "@shared/components/TextInput.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  loadMoreText,
  pastPatientsText,
  searchByThePatientNameText,
  openText,
  editText,
} from "@shared/utils/translations"
import { currentUser, auth } from "@local/store/auth"

let query = ""
let rows
let headers
let total = 0
let page = 0
let loading = true

$: {
  console.info(`Switching language to ${$workflowLang}`)
  load()
  updateUserLanguage($workflowLang)
}

async function updateUserLanguage(language) {
  const partnerId = $currentUser.partnerId
  const userId = $currentUser.id
  const userLanguage = $currentUser.language

  if (userLanguage !== language) {
    try {
      const tokens = await patchLanguage({ partnerId, userId, language })
      auth(tokens)
    } catch (err) {
      console.error("Unable to save language preferences", err)
    }
  }
}

async function load() {
  loading = true
  const apiQuery = { defKey: "emrPastPatients", page, query }
  const [newRows, meta] = await getListByDefKey(apiQuery)
  total = meta.total
  rows = page === 0 ? newRows : [...rows, ...newRows]
  headers = rows.length > 0 ? rows[0].columns.map(({ label }) => label) : []
  loading = false
}

function nextPage() {
  page += 1
  load()
}

let debounceTimer
function debounce() {
  clearTimeout(debounceTimer)
  debounceTimer = setTimeout(() => load(), 750)
}

onMount(load)

const actions = {
  open: async function ({ wfId }) {
    navigate(`/doctor-workstation/workflows/${wfId}`)
  },
  edit: async function ({ wfId }) {
    navigate(`/doctor-workstation/workflows/${wfId}/views/drEdit`)
  },
}

function applyStyle(row) {
  let returningType = row.classProps?.returningType
  if (returningType === "renewal") return "row-renewal"
  if (returningType === "followUp") return "row-followUp"
  return ""
}
</script>

<div class="flex flex-col items-center justify-center flex-1">
  <div class="flex flex-col flex-1 w-full px-8 py-12 bg-white flex-grow-1">
    <div class="flex items-center mb-8">
      <h1 class="w-2/3 text-2xl font-semibold">
        {pastPatientsText[$workflowLang]}&nbsp;({total}):
      </h1>
      <div class="w-1/3">
        <TextInput
          name="search"
          leftIcon="search"
          label="{searchByThePatientNameText[$workflowLang]}"
          bind:value="{query}"
          on:valueChange="{debounce}"
        />
      </div>
    </div>

    {#if total > 0}
      <table class="table border border-collapse rounded">
        <thead class="max-md:hidden">
          {#each headers as header}
            <th class="px-4 py-3 text-xl font-medium text-left border-t">
              {header}
            </th>
          {/each}
          <th class="px-4 py-3 text-xl font-medium text-left border-t">
            &nbsp;
          </th>
        </thead>
        <tbody>
          {#each rows as row}
            <tr class="border-t {applyStyle(row)}">
              {#each row.columns as { value }}
                <td class="px-4 py-3 text-xl">
                  <TemplateString content="{value}" />
                </td>
              {/each}
              <td class="px-4 py-3 text-xl align-middle">
                {#each row.buttons as { action }}
                  <span
                    class="icon claim-icon hover:opacity-75"
                    on:click="{() => actions[action](row)}"
                  >
                    {#if action === "open"}
                      {openText[$workflowLang]}
                      <i class="material-icons"> arrow_forward </i>
                    {:else if action === "edit"}
                      {editText[$workflowLang]}
                      <i class="material-icons"> edit </i>
                    {/if}
                  </span>
                {/each}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
      {#if rows.length < total}
        <div class="mt-4 text-center">
          <span class="load-more" on:click="{nextPage}"
            >{loadMoreText[$workflowLang]}</span
          >
        </div>
      {/if}
    {/if}
  </div>
</div>
